import { lazyWithSuspense } from '~/utilities/lazyWithSuspense';
import { ScreenContainer } from '~/singlePageApp/layouts/shared/ScreenContainer';

export const MonthlyAnalyticsUnsubScreen = lazyWithSuspense(
  async () => import('./MonthlyAnalyticsUnsubScreen'),
  {
    loadingState: <ScreenContainer />,
    exportKey: 'MonthlyAnalyticsUnsubScreen',
  },
);
