import { isNotNil } from '~/utilities/type-guards';
import type { TrackStructEventParams } from 'types/global';
import { environment } from '../constantDefinitions';
import { noOpFn, tryCatch } from './util';

// Snowplow is loaded via Google Tag Manager. See ApplicationHelper#google_tag_manager_js.

const shouldLog =
  (environment === 'development' ||
    environment === 'staging' ||
    environment === 'stagingBranchDeploy') &&
  process.env.NODE_ENV !== 'test';

export const trackStructEvent = ({
  category,
  action,
  property,
  label = null,
  value = null,
}: TrackStructEventParams): void => {
  if (shouldLog) {
    // eslint-disable-next-line no-console
    console.warn(
      `Snowplow - Event not sent:\n ${JSON.stringify({ category, action, label, property, value }, null, 2)}\n`,
    );
  }

  tryCatch({
    fn: () =>
      isNotNil(window.snowplow)
        ? window.snowplow('trackStructEvent', category, action, label, property, value)
        : noOpFn,
    errorFn: (error) => {
      console.error(error); // eslint-disable-line no-console
    },
  });
};
