/* eslint-disable */
/*
 * This was automatically generated by frontend/onboarding/onboarding.ts.
 * DO NOT MANUALLY EDIT
 * Please refer to https://wistia.github.io/wistia/main/?path=/docs/onboarding-docs-getting-started--docs for more information.
 */

export const activeOnboarding = {
  createFromAnywhere: {
    id: 'createFromAnywhere-a0e6f3bf-d972-4caa-9bf1-3eb91e06c478',
    pillar: 'growth',
    product: 'onboarding'
  },
  analyticsOverviewGuidance: {
    id: 'analyticsOverviewGuidance-c3b6b7fe-1f54-4f58-9c7a-2802f61f46d8',
    pillar: 'growth',
    product: 'onboarding'
  },
  audioDescriptionOrders: {
    id: 'audioDescriptionOrders-b8255e0f-4793-4969-9711-1f64de33dfd3',
    pillar: 'publish',
    product: 'customize'
  },
  customizePlayerGradients: {
    id: 'customizePlayerGradients-bbb26e21-8f7f-4ae6-9636-ffb981dffaf8',
    pillar: 'publish',
    product: 'customize'
  },
  'storybook-preview-hotspot': {
    id: 'storybook-preview-hotspot-4b1733d9-7f6c-448a-be7a-125e6dd6f3c4',
    pillar: 'growth',
    product: 'onboarding'
  }
};

export const SUPPORTED_FEATURE_IDS = [
  'createFromAnywhere',
  'analyticsOverviewGuidance',
  'audioDescriptionOrders',
  'customizePlayerGradients',
  'storybook-preview-hotspot'
] as const
