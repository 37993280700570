import { isBoolean, isNotNil } from '~/utilities/type-guards';
import flagsmith from 'flagsmith';
import type { FlagsmithContextType } from 'flagsmith/react';
import { FlagsmithProvider } from 'flagsmith/react';
import type { JSX } from 'react';
import { createContext, useState, useContext, useCallback } from 'react';
import { flagsmithEnvironmentId, flagCacheTTL } from '../../constantDefinitions';
import { FlagsmithV3IdentityAndTraitsLoader } from './FlagsmithV3IdentityAndTraitsLoader';

const FlagsmithIdentityLoadingContext = createContext(true);
const FlagsmithConfigOptions = {
  // https://docs.flagsmith.com/clients/javascript#initialisation-options
  cacheFlags: true,
  api: '/',
  cacheOptions: { ttl: flagCacheTTL, skipAPI: true },
  enableLogs: false, // set true for logs
  environmentID: flagsmithEnvironmentId,
  headers: { 'X-CSRF-TOKEN': window._auth_token },
  preventFetch: true,
};

export const useFlagsmithIdentityLoading = (): boolean => {
  return useContext(FlagsmithIdentityLoadingContext);
};
export const FeatureFlagProvider = ({
  children,
  skipIdentityLoading = false,
  onFeatureFlagProviderLoaded,
}: {
  children: FlagsmithContextType['children'];
  skipIdentityLoading?: boolean;
  isAnonymous?: boolean;
  onFeatureFlagProviderLoaded?: () => void;
}): JSX.Element | null => {
  const [loading, setLoading] = useState(true);

  // Necessary for testing setup.
  // Can be removed once we have removed customize3 and are fully on customize4
  if (skipIdentityLoading && isNotNil(onFeatureFlagProviderLoaded)) {
    onFeatureFlagProviderLoaded();
  }

  const setLoadingValue = useCallback(
    (value: boolean) => {
      setLoading(value);
      // if value is false, we are not loading and should call the callback.
      // This is used by customize3 to know when to begin loading jamjars
      // Note: We may be able to remove onFeatureFlagProviderLoaded
      // once we are fully on customize4
      if (isBoolean(value) && !value && isNotNil(onFeatureFlagProviderLoaded)) {
        onFeatureFlagProviderLoaded();
      }
    },
    [onFeatureFlagProviderLoaded, setLoading],
  );

  return (
    <FlagsmithProvider
      flagsmith={flagsmith}
      options={FlagsmithConfigOptions}
    >
      <FlagsmithIdentityLoadingContext.Provider value={loading}>
        <>
          <FlagsmithV3IdentityAndTraitsLoader
            setLoading={setLoadingValue}
            skip={skipIdentityLoading}
          />
          {children}
        </>
      </FlagsmithIdentityLoadingContext.Provider>
    </FlagsmithProvider>
  );
};
