import styled from 'styled-components';

export const INNER_CONTENT_DESKTOP_MAX_WIDTH = '1110px';

export const ScreenContainer = styled.div`
  color: ${({ theme }) => theme.color.grey700};
  margin: ${({ theme }) => `0px auto ${theme.spacing.space04}`};
  max-width: ${({ contentWidth }) => contentWidth || INNER_CONTENT_DESKTOP_MAX_WIDTH};
  padding: 0 var(--layout-content-spacing);
  width: 100%;

  @media screen and (min-width: 2200px) {
    margin-right: auto;
  }
`;
