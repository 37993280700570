// Z-Index Scale
//
// Define the zIndex of all our components in one place so we can easily
// see their relationship and prevents bugs arising from arbitrary z-index values.
//
// Do not edit the z-index scale! Only add component-scoped z-index values.

/* eslint-disable no-unused-vars */
const zIndex0 = 0;
const zIndex1 = 100;
const zIndex2 = 200;
const zIndex3 = 300;
const zIndex4 = 400;
const zIndex5 = 500;
const zIndex10 = 1000;
/* eslint-enable no-unused-vars */

// We want the Dixa widget:
// * Above the footer (zIndex.Footer below) at all times.
// * Below the bulk action bar (zIndex.ActionBar) when closed.
// * Above the bulk action bar when open.
window.dixaChatWidgetZIndexOverrides = {
  closed: zIndex1 + 1,
  open: zIndex2 + 1,
};

const zIndex = {
  Backdrop: zIndex3,

  ContactSelect: zIndex1,

  ActionBar: zIndex2,
  FilterContent: zIndex2,
  MainNavItem: zIndex1,
  EntityInnerContainer: zIndex1,

  AllContentFilter: zIndex2,
  Header: zIndex2,
  OnboardingChecklist: zIndex2,

  DatePicker: zIndex3,
  SubscriberThresholdReachedCallToAction: zIndex3,
  FloatingSidePanel: zIndex1,
  Footer: zIndex1,
  ProjectSelect: zIndex4,

  StatsFilterContainer: zIndex1,

  TableHeader: zIndex1,

  MobileGate: zIndex10,

  OnboardingGuidance: zIndex10,
};

const color = {
  facebookBlue: '#4267b2',
  twitterBlue: '#1da1f2',
};

const breakpoint = {
  mobile: 575,
};

const theme = { breakpoint, color, zIndex };

const vhsRedesignModeTheme = {
  button: {
    borderRadius: '1000px',
    gap: '8px',
  },
  headline: {
    family: "'GT Walsheim', sans-serif",
    headline1: { weight: '700' },
    headline2: { weight: '600' },
    headline3: { weight: '700' },
  },
  paper: {
    elevation0: 'none',
    elevation1: 'none',
    elevation2: 'none',
  },
  buttonGroup: {
    gap: '8px',
  },
};

export const wistiaTheme = { ...theme, ...vhsRedesignModeTheme };
