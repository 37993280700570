import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';
import { noOpFn } from '~/utilities/util';
import type { SupportedFeatureIds } from '~/onboarding/types';
import type { Nilable, Undefinable } from '~/utilities/type-guards';
import type { Experience, OnboardingConfiguration } from './OnboardingProvider';

export type OnboardingContextType = {
  configure: (config: OnboardingConfiguration) => void;
  show: (featureId: string) => void;
  hide: (featureId: string) => void;
  next: (featureId: string) => void;
  prev: (featureId: string) => void;
  getCurrentExperience: () => Nilable<Experience>;
  getCurrentFeatureId: () => Undefinable<SupportedFeatureIds>;
  currentIndex: number;
  setCurrentIndex: Dispatch<SetStateAction<number>>;
  getCurrentStepId: () => Nilable<string>;
};

export const NOOP_ONBOARDING_CONTEXT: OnboardingContextType = {
  configure: noOpFn,
  show: noOpFn,
  hide: noOpFn,
  next: noOpFn,
  prev: noOpFn,
  getCurrentExperience: () => undefined,
  getCurrentFeatureId: () => undefined,
  currentIndex: -1,
  setCurrentIndex: noOpFn,
  getCurrentStepId: () => undefined,
};

export const OnboardingContext = createContext<OnboardingContextType | null>(null);
